<template>
  <div
    class="address-box"
  >
    <div class="field has-addons mb-2 address-field">
      <p class="p-0 mb-0 control column is-10">
        <b-field
          :type="errors[field.id] && !values['street_' + field.id] ? 'is-danger' : ''"
        >
          <b-autocomplete
            :value="values['street_' + field.id]"
            :data="addresses"
            size="is-small"
            :custom-formatter="() => values['street_' + field.id]"
            :placeholder="field.properties.basic.labels.street"
            @typing="callDebounceAddress($event)"
            @select="option => handleUpdate(option)"
            @input="handleUpdate($event, 'street')"
          >
            <template #default="slotProp">
              <p>{{ slotProp.option.label }}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </p>
      <p class="p-0 mb-0 control column is-2">
        <b-field
          :type="errors[field.id] && !values['number_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['number_' + field.id]"
            size="is-small"
            :placeholder="field.properties.basic.labels.number"
            @input="handleUpdate($event, 'houseNumber')"
          />
        </b-field>
      </p>
    </div>
    <div class="field has-addons mb-2 address-field">
      <p
        v-if="field.properties.basic.hasState"
        class="control p-0 mb-0 column is-6"
      >
        <b-field
          :type="errors[field.id] && !values['state_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['state_' + field.id]"
            size="is-small"
            :disabled="false"
            :placeholder="field.properties.basic.labels.state"
            @input="handleUpdate($event, 'state')"
          />
        </b-field>
      </p>
      <p
        v-if="field.properties.basic.hasDistrict"
        class="control column p-0 mb-0 is-6"
      >
        <b-field
          :type="errors[field.id] && !values['district_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['district_' + field.id]"
            size="is-small"
            :placeholder="field.properties.basic.labels.district"
            @input="handleUpdate($event, 'district')"
          />
        </b-field>
      </p>
    </div>
    <div class="field has-addons mb-2 address-field">
      <p class="control p-0 mb-0 column is-3">
        <b-field
          :type="errors[field.id] && !values['zipCode_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['zipCode_' + field.id]"
            size="is-small"
            :disabled="false"
            :maxlength="5"
            :placeholder="field.properties.basic.labels.zipCode"
            class="address-zipcode"
            @input="handleUpdate($event, 'postalCode')"
          />
        </b-field>
      </p>
      <p class="control column mb-0 p-0 is-9">
        <b-field
          :type="errors[field.id] && !values['city_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['city_' + field.id]"
            size="is-small"
            :placeholder="field.properties.basic.labels.city"
            @input="handleUpdate($event, 'city')"
          />
        </b-field>
      </p>
    </div>
    <div class="field has-addons mb-2 address-field">
      <p
        v-if="field.properties.basic.hasCountry"
        class="control column p-0 mb-0 is-12"
      >
        <b-field
          :type="errors[field.id] && !values['country_' + field.id] ? 'is-danger' : ''"
        >
          <b-input
            :value="values['country_' + field.id]"
            size="is-small"
            :placeholder="field.properties.basic.labels.country"
            @input="handleUpdate($event, 'country')"
          />
        </b-field>
      </p>
    </div>
  </div>
</template>

<script >
import { ref } from '@vue/composition-api';
import axios from 'axios';
import { debounce } from 'lodash';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const addresses = ref([]);
  const callDebounceAddress = debounce(async address => {
    await checkAddress(address);
  }, 500);
  const checkAddress = async address => {
    const zipCode = props.values['zipCode_' + props.field.id];
    const response = await axios({
      method: 'GET',
      url: `${process.env.VUE_APP_APP_SERVICE_DOMAIN}/address`,
      params: {
        zipCode,
        address
      }
    });
    addresses.value = response.data;
  };
  const handleUpdate = debounce((value, updatedField = '') => {
    const {
      id
    } = props.field;
    if (updatedField) {
      value = {
        address: {
          street: props.values['street_' + id],
          postalCode: props.values['zipCode_' + id],
          city: props.values['city_' + id],
          country: props.values['country_' + id] || 'Deutschland',
          houseNumber: props.values['number_' + id],
          state: props.values['state_' + id] ?? '',
          district: props.values['district_' + id] ?? '',
          [updatedField]: value
        }
      };
    }
    emit('update', {
      value
    });
  });
  return {
    addresses,
    callDebounceAddress,
    handleUpdate
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.address-zipcode {
  .is-invisible {
    display: none !important;
  }
}

</style>